import { PREDICT_TYPE } from '../../../../Util/define';
import CharAI from '../../../Control/showAI/charAI';
import './numCNN.css';

function NumCNN(props) {
  return (
    <CharAI
      viewBreadcrumb={'数字認識 - CNNモデル'}
      modelName={'CNN'}
      overview={`CNN分類モデルを使用した数字認識AIモデルです。
        テストデータに対し、97.98%と良好な精度を示しました。
        しかしながら、数字の大きさによって予測精度にばらつきがみられます。`}
      kindName={'数字'}
      predictType={PREDICT_TYPE.NUMBER_CNN}
      keyname={'digit'}
      href={'https://aismiley.co.jp/ai_news/cnn/'}
    />
  );
}

export default NumCNN;
