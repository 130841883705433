import {
    Box,
    Button,
    ButtonGroup,
    Card,
    CardActionArea,
    CardContent,
    CardMedia,
    Paper,
    Typography,
  } from "@mui/material";
  import "./notFound.css"
  import WarningAmberIcon from '@mui/icons-material/WarningAmber';
  import HomeIcon from '@mui/icons-material/Home';
import { Home } from "@mui/icons-material";
import DrawIcon from '@mui/icons-material/Draw';
import { useNavigate } from "react-router-dom";
import { PAGE_ID } from "../../../Util/define";
  
  function NotFound() {
    const navigate = useNavigate();
    return (
      <div className="pcNotFound">
        
        <Box sx={{display: "flex", alignItems:"center"}}>
            <WarningAmberIcon sx={{color:"#fbc02d", fontSize:"70px"}}/>
            <Box sx={{marginLeft:"20px"}}>
            <Typography sx={{fontWeight:"bold"}}>{"404 - File Not Found"}</Typography>
            <Typography sx={{fontWeight:"bold"}}>{"アクセスしようとしたページは見つかりませんでした。"}</Typography>
            <Typography sx={{marginTop:"10px"}}>URLが間違っているか、ページが削除された可能性がございます。</Typography>
            <Typography>ご不便おかけしますが、URLをご確認いただくか、</Typography>
            <Typography>サイドメニューから文字認識メニューをご選択ください。</Typography>
            </Box>
        </Box>
        <Paper sx={{marginTop:"20px", padding:"20px"}}>
            <Typography>SuekichiAIは文字認識AIを搭載したWebアプリケーションです。</Typography>
            <Box sx={{marginTop:"10px"}}>
                <Button variant="outlined" startIcon={<Home/>} onClick={() => navigate("/")}>ホームへ戻る</Button>
                <Button variant="outlined" sx={{marginLeft:"10px"}} startIcon={<DrawIcon/>} onClick={() => navigate("/" + PAGE_ID.HIRAGANA_CNN)}>ひらがな認識</Button>
                <Button variant="outlined" sx={{marginLeft:"10px"}} startIcon={<DrawIcon/>} onClick={() => navigate("/" + PAGE_ID.NUMBER_CNN)}>数字認識</Button>
                <Button variant="outlined" sx={{marginLeft:"10px"}} startIcon={<DrawIcon/>} onClick={() => navigate("/" + PAGE_ID.SENTENCE_CNN)}>複数数字認識</Button>
                </Box>
        </Paper>
      </div>
    );
  }
  
  export default NotFound;
  