import {
  Badge,
  Box,
  Button,
  Collapse,
  Drawer,
  FormHelperText,
  TextField,
  Typography,
} from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ParkIcon from '@mui/icons-material/Park';
import PatternIcon from '@mui/icons-material/Pattern';
import './sideMenu.css';
import React, { useEffect, useState } from 'react';
import { PAGE_ID } from '../../../Util/define';
import { useLocation, useNavigate } from 'react-router-dom';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import HistoryIcon from '@mui/icons-material/History';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import {
  userInfo,
  loginUserFullName,
  loginUserName,
  toastState,
} from '../../../Util/recoil/atom';
import ControlDialog from '../../../Util/commonComponent/controlDialog';
import { FindUserByUserId, RegistUser } from '../../../Util/web_api';
import logo from './../../../image/logo.PNG';

function MSideMenu(props) {
  const [isOpenMenu, setIsOpenMenu] = useState(props.isOpenMenu);
  const [openHiraganaMenu, setOpenHiraganaMenu] = useState(false);
  const [openNumMenu, setOpenNumMenu] = useState(false);
  const [openSentenceMenu, setOpenSentenceMenu] = useState(false);
  const [openLogMenu, setOpenLogMenu] = useState(false);
  const [isOpenDialog, setIsOpenDialog] = useState(false);
  const [nickname, setNickname] = useState('');
  const [errors, setErrors] = useState({});

  // recoil
  const _setToastState = useSetRecoilState(toastState);
  const [_userInfo, setUserInfo] = useRecoilState(userInfo);

  useEffect(() => {
    const userId = localStorage.getItem('SUEKICHIAI_UserId');
    if (userId != null) {
      FindUserByUserId(
        userId,
        (res) => {
          setUserInfo({
            userId: res.data.id,
            nickname: res.data.nickname,
          });
          _setToastState({
            isOpen: true,
            context: `Hello  「${res.data.nickname}」, Welcome to SuekichiAI :)`,
          });
        },
        (e) => {
          console.warn(e);
        }
      );
    } else {
      setIsOpenDialog(true);
    }
  }, []);

  useEffect(() => {
    console.log(props.isOpenMenu);

    setIsOpenMenu(props.isOpenMenu);
  }, [props.isOpenMenu]);

  const handleRegister = () => {
    if (!nickname) {
      setErrors({ nickname: 'ニックネームを入力してください。' });
    }

    const poster = {
      nickname: nickname,
    };

    RegistUser(
      poster,
      (res) => {
        localStorage.setItem('SUEKICHIAI_UserId', res.data.id);
        setUserInfo({
          userId: res.data.id,
          nickname: res.data.nickname,
        });
        _setToastState({
          isOpen: true,
          context: `Hello  「${res.data.nickname}」, Welcome to SuekichiAI :)`,
        });
        setIsOpenDialog(false);
      },
      (e) => {
        console.warn(e);
      }
    );
  };

  const content = () => {
    return (
      <div>
        <TextField
          label="ニックネーム"
          variant="outlined"
          value={nickname}
          onChange={(e) => setNickname(e.target.value)}
          error={!!errors.nickname}
          helperText={errors.nickname}
          required
          style={{ width: '220px' }}
          sx={{ marginTop: '10px' }}
        />
      </div>
    );
  };

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (_userInfo.companyId === '') {
      navigate('/');
    }
  }, []);

  return (
    <div>
      <ControlDialog
        isOpen={isOpenDialog}
        doYes={() => {
          handleRegister();
        }}
        doNo={() => {
          setIsOpenDialog(false);
        }}
        yesText={'登録'}
        title={'初回ニックネーム登録'}
        content={content()}
      />
      <Drawer
        open={props.isOpenMenu}
        onClose={() => props.setIsOpenMenu(false)}
        sx={{
          width: 265, // Drawerの幅
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: 265, // Drawer内部のペーパーの幅
            boxSizing: 'border-box',
          },
        }}
      >
        <img
          src={logo}
          width={220}
          style={{ marginTop: '10px', marginLeft: '5px' }}
        />

        <Button
          className="mfullWidth"
          color={location.pathname === '/' ? 'info' : 'inherit'}
          style={{
            justifyContent: 'flex-start',
            marginLeft: '2%',
            marginTop: '5px',
          }}
          sx={{
            backgroundColor: location.pathname === '/' && '#e3f2fd',
            '&:hover': {
              backgroundColor: location.pathname === '/' && '#bbdefb',
            },
          }}
          startIcon={<HomeIcon />}
          onClick={() => {
            navigate('/');
            props.setIsOpenMenu(false);
          }}
        >
          ホーム
        </Button>
        <Button
          className="mfullWidth"
          color="inherit"
          style={{ justifyContent: 'flex-start', marginLeft: '2%' }}
          startIcon={
            openHiraganaMenu ? <ExpandMoreIcon /> : <ChevronRightIcon />
          }
          onClick={() => setOpenHiraganaMenu(!openHiraganaMenu)}
        >
          ひらがな
        </Button>
        <Collapse in={openHiraganaMenu} timeout="auto">
          <Button
            className="mfullWidth"
            color={
              location.pathname === '/' + PAGE_ID.HIRAGANA_TREE
                ? 'info'
                : 'inherit'
            }
            style={{
              justifyContent: 'flex-start',
              marginLeft: '2%',
              paddingLeft: '15%',
            }}
            sx={{
              backgroundColor:
                location.pathname === '/' + PAGE_ID.HIRAGANA_TREE && '#e3f2fd',
              '&:hover': {
                backgroundColor:
                  location.pathname === '/' + PAGE_ID.HIRAGANA_TREE &&
                  '#bbdefb',
              },
            }}
            startIcon={<ParkIcon />}
            onClick={() => {
              navigate('/' + PAGE_ID.HIRAGANA_TREE);
              props.setIsOpenMenu(false);
            }}
          >
            RF分類モデル
          </Button>
          <Button
            className="mfullWidth"
            color={
              location.pathname === '/' + PAGE_ID.HIRAGANA_CNN
                ? 'info'
                : 'inherit'
            }
            style={{
              justifyContent: 'flex-start',
              marginLeft: '2%',
              paddingLeft: '15%',
            }}
            sx={{
              backgroundColor:
                location.pathname === '/' + PAGE_ID.HIRAGANA_CNN && '#e3f2fd',
              '&:hover': {
                backgroundColor:
                  location.pathname === '/' + PAGE_ID.HIRAGANA_CNN && '#bbdefb',
              },
            }}
            startIcon={<PatternIcon />}
            onClick={() => {
              navigate('/' + PAGE_ID.HIRAGANA_CNN);
              props.setIsOpenMenu(false);
            }}
          >
            CNNモデル
          </Button>
        </Collapse>
        <Button
          className="mfullWidth"
          color="inherit"
          style={{ justifyContent: 'flex-start', marginLeft: '2%' }}
          startIcon={openNumMenu ? <ExpandMoreIcon /> : <ChevronRightIcon />}
          onClick={() => setOpenNumMenu(!openNumMenu)}
        >
          数字
        </Button>
        <Collapse in={openNumMenu} timeout="auto">
          <Button
            className="mfullWidth"
            color={
              location.pathname === '/' + PAGE_ID.NUMBER_TREE
                ? 'info'
                : 'inherit'
            }
            style={{
              justifyContent: 'flex-start',
              marginLeft: '2%',
              paddingLeft: '15%',
            }}
            sx={{
              backgroundColor:
                location.pathname === '/' + PAGE_ID.NUMBER_TREE && '#e3f2fd',
              '&:hover': {
                backgroundColor:
                  location.pathname === '/' + PAGE_ID.NUMBER_TREE && '#bbdefb',
              },
            }}
            startIcon={<ParkIcon />}
            onClick={() => {
              navigate('/' + PAGE_ID.NUMBER_TREE);
              props.setIsOpenMenu(false);
            }}
          >
            RF分類モデル
          </Button>
          <Button
            className="mfullWidth"
            color={
              location.pathname === '/' + PAGE_ID.NUMBER_CNN
                ? 'info'
                : 'inherit'
            }
            style={{
              justifyContent: 'flex-start',
              marginLeft: '2%',
              paddingLeft: '15%',
            }}
            sx={{
              backgroundColor:
                location.pathname === '/' + PAGE_ID.NUMBER_CNN && '#e3f2fd',
              '&:hover': {
                backgroundColor:
                  location.pathname === '/' + PAGE_ID.NUMBER_CNN && '#bbdefb',
              },
            }}
            startIcon={<PatternIcon />}
            onClick={() => {
              navigate('/' + PAGE_ID.NUMBER_CNN);
              props.setIsOpenMenu(false);
            }}
          >
            CNNモデル
          </Button>
        </Collapse>
        <Button
          className="mfullWidth"
          color="inherit"
          style={{ justifyContent: 'flex-start', marginLeft: '2%' }}
          startIcon={
            openSentenceMenu ? <ExpandMoreIcon /> : <ChevronRightIcon />
          }
          onClick={() => setOpenSentenceMenu(!openSentenceMenu)}
        >
          複数数字
        </Button>
        <Collapse in={openSentenceMenu} timeout="auto">
          <Button
            className="mfullWidth"
            color={
              location.pathname === '/' + PAGE_ID.SENTENCE_TREE
                ? 'info'
                : 'inherit'
            }
            style={{
              justifyContent: 'flex-start',
              marginLeft: '2%',
              paddingLeft: '15%',
            }}
            sx={{
              backgroundColor:
                location.pathname === '/' + PAGE_ID.SENTENCE_TREE && '#e3f2fd',
              '&:hover': {
                backgroundColor:
                  location.pathname === '/' + PAGE_ID.SENTENCE_TREE &&
                  '#bbdefb',
              },
            }}
            startIcon={<ParkIcon />}
            onClick={() => {
              navigate('/' + PAGE_ID.SENTENCE_TREE);
              props.setIsOpenMenu(false);
            }}
          >
            RF分類モデル
          </Button>
          <Button
            className="mfullWidth"
            color={
              location.pathname === '/' + PAGE_ID.SENTENCE_CNN
                ? 'info'
                : 'inherit'
            }
            style={{
              justifyContent: 'flex-start',
              marginLeft: '2%',
              paddingLeft: '15%',
            }}
            sx={{
              backgroundColor:
                location.pathname === '/' + PAGE_ID.SENTENCE_CNN && '#e3f2fd',
              '&:hover': {
                backgroundColor:
                  location.pathname === '/' + PAGE_ID.SENTENCE_CNN && '#bbdefb',
              },
            }}
            startIcon={<PatternIcon />}
            onClick={() => {
              navigate('/' + PAGE_ID.SENTENCE_CNN);
              props.setIsOpenMenu(false);
            }}
          >
            CNNモデル
          </Button>
        </Collapse>
        <Button
          className="mfullWidth"
          color="inherit"
          style={{ justifyContent: 'flex-start', marginLeft: '2%' }}
          startIcon={openLogMenu ? <ExpandMoreIcon /> : <ChevronRightIcon />}
          onClick={() => setOpenLogMenu(!openLogMenu)}
        >
          ログ
        </Button>
        <Collapse in={openLogMenu} timeout="auto">
          <Button
            className="mfullWidth"
            color={
              location.pathname === '/' + PAGE_ID.LOG_PERSONAL
                ? 'info'
                : 'inherit'
            }
            style={{
              justifyContent: 'flex-start',
              marginLeft: '2%',
              paddingLeft: '15%',
            }}
            sx={{
              backgroundColor:
                location.pathname === '/' + PAGE_ID.LOG_PERSONAL && '#e3f2fd',
              '&:hover': {
                backgroundColor:
                  location.pathname === '/' + PAGE_ID.LOG_PERSONAL && '#bbdefb',
              },
            }}
            startIcon={<HistoryIcon />}
            onClick={() => {
              navigate('/' + PAGE_ID.LOG_PERSONAL);
              props.setIsOpenMenu(false);
            }}
          >
            認識ログ(個人)
          </Button>
          <Button
            className="mfullWidth"
            color={
              location.pathname === '/' + PAGE_ID.LOG_GENERAL
                ? 'info'
                : 'inherit'
            }
            style={{
              justifyContent: 'flex-start',
              marginLeft: '2%',
              paddingLeft: '15%',
            }}
            sx={{
              backgroundColor:
                location.pathname === '/' + PAGE_ID.LOG_GENERAL && '#e3f2fd',
              '&:hover': {
                backgroundColor:
                  location.pathname === '/' + PAGE_ID.LOG_GENERAL && '#bbdefb',
              },
            }}
            startIcon={<ManageSearchIcon />}
            onClick={() => {
              navigate('/' + PAGE_ID.LOG_GENERAL);
              props.setIsOpenMenu(false);
            }}
          >
            認識ログ(全体)
          </Button>
        </Collapse>
        {_userInfo.nickname != '' && (
          <React.Fragment>
            <FormHelperText
              sx={{
                justifyContent: 'flex-start',
                paddingLeft: '5%',
                paddingRight: '3%',
                width: '240px',
              }}
            >
              あなたのニックネームは 「
              <Typography component="span" sx={{ color: '#1976d2' }}>
                {`${_userInfo.nickname}`}
              </Typography>
              」です
            </FormHelperText>
          </React.Fragment>
        )}
      </Drawer>
    </div>
  );
}

export default MSideMenu;
