import {
  Box,
  Button,
  Card,
  CircularProgress,
  Divider,
  FormHelperText,
  Link,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Paper,
  Typography,
} from '@mui/material';
import './charAI.css';
import React, { useEffect, useRef, useState } from 'react';
import ManageSearchOutlinedIcon from '@mui/icons-material/ManageSearchOutlined';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import LooksOneIcon from '@mui/icons-material/LooksOne';
import LooksTwoIcon from '@mui/icons-material/LooksTwo';
import Looks3Icon from '@mui/icons-material/Looks3';
import Looks4Icon from '@mui/icons-material/Looks4';
import Looks5Icon from '@mui/icons-material/Looks5';
import tmpImg from '../../../image/tmp.png';
import {
  ChatOpenAIWithMessage,
  FindPredictsTop3Myself,
  PredictHiraganaByCNNModel,
  PredictHiraganaByTreeModel,
  PredictNumByCNNModel,
  RegistPredict,
} from '../../../Util/web_api';
import { saveAs } from 'file-saver';
import { useRecoilValue } from 'recoil';
import { userInfo } from '../../../Util/recoil/atom';
import { FormatDateFromStrConsider, TimeToUTC } from '../../../Util/function';
import { PREDICT_ADDRESS, PREDICT_TYPE } from '../../../Util/define';
import ControlDialog from '../../../Util/commonComponent/controlDialog';
import LaunchIcon from '@mui/icons-material/Launch';

function CharAI(props) {
  const {
    viewBreadcrumb,
    modelName,
    overview,
    kindName,
    predictType,
    keyname,
    href,
  } = props;
  const canvasRef = useRef(null);
  const [isDrawing, setIsDrawing] = useState(false);
  const [context, setContext] = useState(null);
  const [predict, setPredict] = useState([]);
  const [logs, setLogs] = useState([]);
  const [isOpenDialog, setIsOpenDialog] = useState(false);
  const [isWrite, setIsWrite] = useState(false);
  const [isLoad, setIsLoad] = useState(false);
  const [gptMessage, setGptMessage] = useState('OpenAIによる要約を表示します');

  // recoil
  const _userInfo = useRecoilValue(userInfo);

  useEffect(() => {
    findPredict();
    // Canvasの初期設定
    const canvas = canvasRef.current;
    canvas.width = 360; // キャンバスの幅
    canvas.height = 360; // キャンバスの高さ
    const ctx = canvas.getContext('2d');
    ctx.lineWidth = 25; // 線の太さ
    ctx.lineCap = 'round'; // 線の端を丸くする
    ctx.strokeStyle = 'black'; // 線の色
    ctx.fillStyle = 'white'; // 背景色を白に設定
    ctx.fillRect(0, 0, canvas.width, canvas.height); // 背景を塗りつぶす
    setContext(ctx);
  }, []);

  useEffect(() => {
    if (predict.length > 0) {
      setIsLoad(true);
      ChatOpenAIWithMessage(
        `${JSON.stringify(
          predict
        )}これは文字認識の結果です。結果的には何の文字であると推論されたのか、それ以外の推論と比較してどうなのかを80文字以内でまとめてください。`,
        (res) => {
          setGptMessage(res.data.choices[0].message.content);
          setIsLoad(false);
        },
        (e) => {
          console.warn(e);
          setIsLoad(false);
        }
      );
    }
  }, [predict]);

  const registPredict = (image, predict) => {
    const poster = {
      userRelationId: _userInfo.userId,
      predictType: predictType,
      predictDate: TimeToUTC(new Date()),
      image: image,
      predict1: String(predict[0][keyname]),
      predict1Probability: predict[0].probability,
      predict2: String(predict[1][keyname]),
      predict2Probability: predict[1].probability,
      predict3: String(predict[2][keyname]),
      predict3Probability: predict[2].probability,
      predict4: String(predict[3][keyname]),
      predict4Probability: predict[3].probability,
      predict5: String(predict[4][keyname]),
      predict5Probability: predict[4].probability,
    };

    RegistPredict(
      poster,
      (res) => {
        setLogs((prevData) => {
          // 先頭に新しいデータを追加し、末尾を削除
          if (logs.length === 3) {
            const updatedData = [
              res.data,
              ...prevData.slice(0, prevData.length - 1),
            ];
            return updatedData;
          } else {
            const updatedData = [res.data, ...prevData];
            return updatedData;
          }
        });
      },
      (e) => {
        console.warn(e);
      }
    );
  };

  const findPredict = () => {
    FindPredictsTop3Myself(
      _userInfo.userId,
      predictType,
      (res) => {
        setLogs(res.data);
      },
      (e) => {
        console.warn(e);
      }
    );
  };

  const startDrawing = (event) => {
    const { offsetX, offsetY } = event.nativeEvent;
    context.beginPath(); // パスを開始
    context.moveTo(offsetX, offsetY); // パスの開始点
    setIsDrawing(true);
  };

  const draw = (event) => {
    if (!isDrawing) return;

    if (!isWrite) {
      setIsWrite(true);
    }

    const { offsetX, offsetY } = event.nativeEvent;
    context.lineTo(offsetX, offsetY); // パスを引く
    context.stroke(); // パスを描画
  };

  const stopDrawing = () => {
    context.closePath(); // パスを閉じる
    setIsDrawing(false);
  };

  const clearCanvas = () => {
    context.fillRect(0, 0, canvasRef.current.width, canvasRef.current.height); // 背景を塗りつぶす
    setIsWrite(false);
  };

  const exportToBase64 = () => {
    if (!isWrite) {
      setIsOpenDialog(true);
      return;
    }
    const canvas = canvasRef.current;
    const dataURL = canvas.toDataURL('image/png'); // Base64形式の画像データを取得
    switch (predictType) {
      case PREDICT_TYPE.HIRAGANA_CNN:
        PredictHiraganaByCNNModel(
          dataURL,
          (res) => {
            console.log(res.data.top_5_predictions);

            setPredict(res.data.top_5_predictions);
            registPredict(dataURL, res.data.top_5_predictions);
          },
          (e) => {
            console.warn(e);
          }
        );
        break;
      case PREDICT_TYPE.HIRAGANA_TREE:
        PredictHiraganaByTreeModel(
          dataURL,
          (res) => {
            setPredict(res.data.top_5_predictions);
            registPredict(dataURL, res.data.top_5_predictions);
          },
          (e) => {
            console.warn(e);
          }
        );
        break;
      case PREDICT_TYPE.NUMBER_CNN:
        PredictNumByCNNModel(
          dataURL,
          (res) => {
            setPredict(res.data.top_5_predictions);
            registPredict(dataURL, res.data.top_5_predictions);
          },
          (e) => {
            console.warn(e);
          }
        );
        break;
      default:
        break;
    }
  };

  return (
    <div className="pcCharAI">
      <ControlDialog
        isOpen={isOpenDialog}
        doYes={() => setIsOpenDialog(false)}
        yesText={'OK'}
        title={'無描画のためエラー'}
        content={
          <Typography>キャンパスに{kindName}を書いてください</Typography>
        }
      />
      <Typography>{viewBreadcrumb}</Typography>
      <Card variant="outlined" sx={{ padding: '10px' }}>
        <Typography>{'モデル概要'}</Typography>
        <Typography sx={{ marginLeft: '3px', marginTop: '3px' }}>
          {overview}
        </Typography>
        <Link
          href={href}
          target="_blank"
          rel="noopener noreferrer"
          sx={{
            display: 'flex',
            alignItems: 'center',
            width: modelName === 'ランダムフォレスト' ? '270px' : '160px',
          }}
        >
          <LaunchIcon />
          {`${modelName}モデルとは？`}
        </Link>
      </Card>
      <Box sx={{ marginTop: '20px', marginBottom: '10px' }}>
        <Typography>{`キャンパスに${kindName}を書き、認識ボタン押してください。`}</Typography>
      </Box>
      <Paper elevation={3} sx={{ padding: '10px', display: 'flex' }}>
        <Box
          sx={{
            width: '400px',
            marginRight: '5px',
            borderRight: '1px solid #bdbdbd',
          }}
        >
          <Card
            variant="outlined"
            sx={{ padding: '10px', width: '360px', height: '360px' }}
          >
            <canvas
              ref={canvasRef}
              style={{
                cursor: 'crosshair',
              }}
              onMouseDown={startDrawing}
              onMouseMove={draw}
              onMouseUp={stopDrawing}
              onMouseLeave={stopDrawing}
            />
          </Card>

          <Button
            variant="contained"
            sx={{ marginTop: '5px' }}
            startIcon={<ManageSearchOutlinedIcon />}
            onClick={exportToBase64}
          >
            {kindName}を認識
          </Button>
          <Button
            variant="outlined"
            sx={{ marginTop: '5px', marginLeft: '10px' }}
            startIcon={<RestartAltIcon />}
            onClick={clearCanvas}
          >
            キャンパスを白紙にする
          </Button>
        </Box>
        <Box
          sx={{
            width: '380px',
            paddingRight: '18px',
            borderRight: '1px solid #bdbdbd',
            marginLeft: '13px',
            paddingTop: '5px',
          }}
        >
          <Typography sx={{ marginBottom: '5px' }}>認識結果</Typography>
          {predict.length != 0 ? (
            <React.Fragment>
              <Divider />
              <List sx={{ padding: 0 }}>
                {predict.map((p, i) => (
                  <React.Fragment>
                    <ListItemButton>
                      <ListItemIcon>
                        {i === 0 ? (
                          <LooksOneIcon sx={{ color: '#fbc02d' }} />
                        ) : i === 1 ? (
                          <LooksTwoIcon sx={{ color: '#3f51b5' }} />
                        ) : i === 2 ? (
                          <Looks3Icon sx={{ color: '#ff6d00' }} />
                        ) : i === 3 ? (
                          <Looks4Icon />
                        ) : (
                          <Looks5Icon />
                        )}
                      </ListItemIcon>
                      <ListItemText
                        primary={`${p[keyname]}：${p.probability}%`}
                      />
                    </ListItemButton>
                    <Divider />
                  </React.Fragment>
                ))}
              </List>
              <FormHelperText>上位5件を表示しています</FormHelperText>
              <Card
                variant="outlined"
                sx={{
                  padding: '10px',
                  marginTop: '10px',
                  height: '90px',
                  overflowY: 'auto',
                }}
              >
                <Typography
                  variant="body1"
                  sx={{ color: '#03a9f4', marginBottom: '2px' }}
                >
                  AIによる認識結果評価
                </Typography>
                {isLoad ? (
                  <CircularProgress />
                ) : (
                  <Typography variant="body2">{gptMessage}</Typography>
                )}
              </Card>
            </React.Fragment>
          ) : (
            <Box>結果が表示されます</Box>
          )}
        </Box>
        <Box
          sx={{
            width: '380px',
            paddingRight: '18px',
            marginLeft: '13px',
            paddingTop: '5px',
          }}
        >
          <Typography sx={{ marginBottom: '5px' }}>認識ログ</Typography>
          {logs.length != 0 ? (
            <List sx={{ padding: 0 }}>
              {logs.map((l, i) => (
                <React.Fragment>
                  {i === 0 && <Divider />}
                  <ListItemButton>
                    <img src={l.image} width={100} height={100} />
                    <Box sx={{ marginLeft: '10px' }}>
                      <Typography>
                        {FormatDateFromStrConsider(l.predictDate, 9)}
                      </Typography>
                      <Box>
                        <Typography
                          sx={{ display: 'flex', alignItems: 'center' }}
                        >
                          <LooksOneIcon
                            sx={{ marginRight: '5px', color: '#fbc02d' }}
                          />
                          {`${l.predict1}：${l.predict1Probability}%`}
                        </Typography>
                        <Typography
                          sx={{ display: 'flex', alignItems: 'center' }}
                        >
                          <LooksTwoIcon
                            sx={{ marginRight: '5px', color: '#3f51b5' }}
                          />
                          {`${l.predict2}：${l.predict2Probability}%`}
                        </Typography>
                        <Typography
                          sx={{ display: 'flex', alignItems: 'center' }}
                        >
                          <Looks3Icon
                            sx={{ marginRight: '5px', color: '#ff6d00' }}
                          />
                          {`${l.predict3}：${l.predict3Probability}%`}
                        </Typography>
                      </Box>
                    </Box>
                  </ListItemButton>
                  <Divider />
                </React.Fragment>
              ))}
            </List>
          ) : (
            <React.Fragment>
              <Divider />
              <Typography sx={{ padding: '10px' }}>
                ログが見つかりませんでした
              </Typography>
              <Divider />
            </React.Fragment>
          )}
          <FormHelperText>自分自身のログを直近3件表示しています</FormHelperText>
        </Box>
      </Paper>
    </div>
  );
}

export default CharAI;
