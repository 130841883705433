import {
    Box,
    Button,
    ButtonGroup,
    Card,
    CardActionArea,
    CardContent,
    CardMedia,
    Paper,
    Typography,
  } from "@mui/material";
  import "./commingSoon.css"
  
  function CommingSoon(props) {
    const {name} = props
  
    return (
      <div className="pcCommingSoon">
        <Typography>{name}</Typography>
        <Paper sx={{marginTop:"10px", padding:"10px"}}>
            このページは現在準備です。
        </Paper>
      </div>
    );
  }
  
  export default CommingSoon;
  