import './App.css';
import Header from './PCView/Control/header/header';
import SideMenu from './PCView/Control/sideMenu/sideMenu';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { useRecoilState, useRecoilValue } from 'recoil';
import { isOpenToast, loading, toastState } from './Util/recoil/atom';
import { AxiosClientProvider } from './Util/web_api';
import {
  Backdrop,
  CircularProgress,
  Grow,
  Snackbar,
  useMediaQuery,
} from '@mui/material';
import Home from './PCView/Component/home/home';
import HiraganaCNN from './PCView/Component/hiraganaAI/CNN/hiraganaCNN';
import PersonalLog from './PCView/Component/log/personal/personalLog';
import NumCNN from './PCView/Component/numAI/CNN/numCNN';
import GeneralLog from './PCView/Component/log/general/generalog';
import HiraganaTree from './PCView/Component/hiraganaAI/tree/hiraganaTree';
import NumsCNN from './PCView/Component/contextAI/CNN/numCNN';
import NumTree from './PCView/Component/numAI/tree/numTree';
import NumsTree from './PCView/Component/contextAI/tree/numTree';
import NotFound from './PCView/Control/notFound/notFound';
import MHeader from './MobileView/Control/header/header';
import { useState } from 'react';
import MSideMenu from './MobileView/Control/sideMenu/sideMenu';
import MHome from './MobileView/Component/home/home';
import MHiraganaTree from './MobileView/Component/hiraganaAI/tree/hiraganaTree';
import MHiraganaCNN from './MobileView/Component/hiraganaAI/CNN/hiraganaCNN';
import MNumTree from './MobileView/Component/numAI/tree/numTree';
import MNumCNN from './MobileView/Component/numAI/CNN/numCNN';
import MNumsCNN from './MobileView/Component/contextAI/CNN/numCNN';
import MNumsTree from './MobileView/Component/contextAI/Tree/numTree';
import MPersonalLog from './MobileView/Component/log/personal/personalLog';
import MGeneralLog from './MobileView/Component/log/general/generalog';
import MNotFound from './MobileView/Control/notFound/notFound';

function GrowTransition(props) {
  return <Grow {...props} />;
}

function App(props) {
  const isLoading = useRecoilValue(loading);
  const isMobile = useMediaQuery('(max-width:768px)');
  const [isOpenMobileMenu, setIsOpenMobileMenu] = useState(false);

  const [_toastState, _setToastState] = useRecoilState(toastState);

  const onCloseSnackBar = (e, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    _setToastState({ isOpen: false, context: _toastState.context });
  };

  return (
    <BrowserRouter>
      <AxiosClientProvider>
        {isLoading && (
          <Backdrop sx={{ color: '#fff', zIndex: 1500 }} open={true}>
            <CircularProgress color="inherit" />
          </Backdrop>
        )}
        <Snackbar
          anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
          open={_toastState.isOpen}
          onClose={onCloseSnackBar}
          message={_toastState.context}
          autoHideDuration={5000}
          TransitionComponent={GrowTransition}
        />
        <div className="App">
          {isMobile ? (
            <MHeader
              isOpenMenu={isOpenMobileMenu}
              setIsOpenMenu={setIsOpenMobileMenu}
            />
          ) : (
            <Header />
          )}
          <div className="pcMain" style={{ display: 'flex' }}>
            {isMobile ? (
              <MSideMenu
                isOpenMenu={isOpenMobileMenu}
                setIsOpenMenu={setIsOpenMobileMenu}
              />
            ) : (
              <SideMenu />
            )}
            {isMobile ? (
              <Routes>
                <Route path="*" element={<MNotFound />} />
                <Route path="/" element={<MHome />} />
                <Route path="/Hiragana/RF" element={<MHiraganaTree />} />
                <Route path="/Hiragana/CNN" element={<MHiraganaCNN />} />
                <Route path="/Number/RF" element={<MNumTree />} />
                <Route path="/Number/CNN" element={<MNumCNN />} />
                <Route path="/Sentence/RF" element={<MNumsTree />} />
                <Route path="/Sentence/CNN" element={<MNumsCNN />} />
                <Route path="/Log/Personal" element={<MPersonalLog />} />
                <Route path="/Log/General" element={<MGeneralLog />} />
              </Routes>
            ) : (
              <Routes>
                <Route path="*" element={<NotFound />} />
                <Route path="/" element={<Home />} />
                <Route path="/Hiragana/RF" element={<HiraganaTree />} />
                <Route path="/Hiragana/CNN" element={<HiraganaCNN />} />
                <Route path="/Number/RF" element={<NumTree />} />
                <Route path="/Number/CNN" element={<NumCNN />} />
                <Route path="/Sentence/RF" element={<NumsTree />} />
                <Route path="/Sentence/CNN" element={<NumsCNN />} />
                <Route path="/Log/Personal" element={<PersonalLog />} />
                <Route path="/Log/General" element={<GeneralLog />} />
              </Routes>
            )}
          </div>
        </div>
      </AxiosClientProvider>
    </BrowserRouter>
  );
}

export default App;
