import React, { useEffect, useState } from "react";
import {
  Typography,
  Container,
  Grid,
  Link,
  Box,
  Card,
  CardActionArea,
  CardMedia,
  CardContent,
  Paper,
  Chip,
  Button,
  Divider,
  CircularProgress,
  Popover,
  FormControlLabel,
  Checkbox,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  ListSubheader,
  CardActions,
  TextField,
} from "@mui/material";
import "./home.css";
import WaterDropOutlinedIcon from "@mui/icons-material/WaterDropOutlined";
import KeyboardArrowRightOutlinedIcon from "@mui/icons-material/KeyboardArrowRightOutlined";
import { PieChart } from "@mui/x-charts";
import { useRecoilState, useRecoilValue } from "recoil";
import { userInfo } from "../../../Util/recoil/atom";
import name from "../../../image/name.png";
import uiLogo from "../../../image/ui.png";
import apiLogo from "../../../image/apiLogo.png";
import aiLogo from "../../../image/aiLogo.png";
import { FindUserByUserId, RegistUser } from "../../../Util/web_api";
import ControlDialog from "../../../Util/commonComponent/controlDialog";
import WebIcon from "@mui/icons-material/Web";
import ApiIcon from "@mui/icons-material/Api";
import PsychologyAltIcon from "@mui/icons-material/PsychologyAlt";

function MHome() {
  const [isOpenFrontDialog, setIsOpenFrontDialog] = useState(false);
  const [isOpenBackDialog, setIsOpenBackDialog] = useState(false);
  const [isOpenAIDialog, setIsOpenAIDialog] = useState(false);
  return (
    <div className="mobileHome">
      <ControlDialog
        isOpen={isOpenFrontDialog}
        doYes={() => setIsOpenFrontDialog(false)}
        yesText={"閉じる"}
        title={"フロントエンド"}
        content={
          <Box>
            <CardActionArea>
              <CardMedia component="img" image={uiLogo} alt="green iguana" />
              <CardContent>
                <Typography variant="body2" sx={{ color: "text.secondary" }}>
                  画面の構造を作るために <strong>HTML</strong>{" "}
                  <strong>CSS</strong> <strong>JavaScript</strong>{" "}
                  <strong>TypeScript</strong> を使用しています。
                </Typography>
                <Typography
                  variant="body2"
                  sx={{ color: "text.secondary", marginTop: "8px" }}
                >
                  ユーザーインターフェースを効率的に構築するために{" "}
                  <strong>React</strong> というライブラリを使用し、 さらに{" "}
                  <strong>Material-UI (MUI)</strong>{" "}
                  を使って、洗練されたデザインのコンポーネントを
                  簡単に組み合わせられるようにしています。
                  これにより、モダンで機能的なフロントエンドを実現しています。
                </Typography>
                <Typography
                  variant="body2"
                  sx={{ color: "text.secondary", marginTop: "8px" }}
                >
                  また、開発環境やビルドツールとして <strong>Node.js</strong>{" "}
                  を利用し、
                  サーバーサイドとの連携やアプリケーション全体の効率を高めています。
                </Typography>
              </CardContent>
            </CardActionArea>
          </Box>
        }
      />
      <ControlDialog
        isOpen={isOpenBackDialog}
        doYes={() => setIsOpenBackDialog(false)}
        yesText={"閉じる"}
        title={"バッグエンド・API"}
        content={
          <Box>
            <CardActionArea>
              <CardMedia component="img" image={apiLogo} alt="green iguana" />
              <CardContent>
                <Typography variant="body2" sx={{ color: "text.secondary" }}>
                  <strong>C#</strong> や <strong>Python</strong>
                  という言語を使用し、APIやバックエンドの機能を実装しています。
                </Typography>
                <Typography
                  variant="body2"
                  sx={{ color: "text.secondary", marginTop: "8px" }}
                >
                  予測を行う処理は <strong>Flask</strong>{" "}
                  を使用し、バッグエンドでPythonコードを実行しています。
                  <br />
                  データベースとのやり取りには{" "}
                  <strong>Entity Framework Core (EF Core)</strong>{" "}
                  を使い、データは <strong>SQL Server</strong>{" "}
                  に保存されております。
                  <br />
                  バックグラウンドで動く定期的な作業を <strong>
                    Hangfire
                  </strong>{" "}
                  というツールを使って行っています。
                </Typography>
                <Typography
                  variant="body2"
                  sx={{ color: "text.secondary", marginTop: "8px" }}
                >
                  システムは <strong>Windows Server</strong>{" "}
                  上にデプロイすることで、クラウド型Webアプリケーションとして動作しています。
                </Typography>
              </CardContent>
            </CardActionArea>
          </Box>
        }
      />
      <ControlDialog
        isOpen={isOpenAIDialog}
        doYes={() => setIsOpenAIDialog(false)}
        yesText={"閉じる"}
        title={"AI・機械学習"}
        content={
          <Box>
            <CardActionArea>
              <CardMedia component="img" image={aiLogo} alt="green iguana" />
              <CardContent>
                <Typography variant="body2" sx={{ color: "text.secondary" }}>
                  <strong>Python</strong>{" "}
                  という言語を使用しAI・機械学習機能を実装しています。
                  <br />
                  開発環境としては、<strong>Anaconda</strong>{" "}
                  で仮想環境を作成し、
                  <strong>Jupyter Notebook</strong>{" "}
                  を使用してコーディングを行いました。。
                </Typography>
                <Typography
                  variant="body2"
                  sx={{ color: "text.secondary", marginTop: "8px" }}
                >
                  <strong>NumPy</strong>{" "}
                  を使用し、画像をデータとして扱っています。
                  <br />
                  機械学習には <strong>scikit-learn (sklearn)</strong>{" "}
                  を使用し、ランダムフォレストモデルを作成しています。
                  <br />
                  より精度の高い予測を行うために、<strong>
                    TensorFlow
                  </strong> や <strong>Keras</strong>{" "}
                  といったツールを使用して、CNNというモデルを作成しています。
                  <br />
                  機械学習の進捗を可視化するのに
                  <strong> matplotlib</strong> を使用し、
                  作成したモデルを保存して再利用するために、
                  <strong>joblib</strong> <strong>TensorFlow</strong>{" "}
                  を使用しています。
                </Typography>
              </CardContent>
            </CardActionArea>
          </Box>
        }
      />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          marginTop: "10px",
        }}
      >
        <Box
          sx={{
            width: "100%",
            display: "flex",
            alignItems: "center",
          }}
        >
          <img src={name} width={"98%"} />
        </Box>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            marginTop: "10px",
          }}
        >
          <Box sx={{ width: "50%" }}>
            <Divider sx={{ borderColor: "#00b0ff" }} />
          </Box>
          <Typography
            variant="h6"
            sx={{ width: "150px", textAlign: "center", color: "#0091ea" }}
          >
            ご挨拶
          </Typography>
          <Box sx={{ width: "50%" }}>
            <Divider sx={{ borderColor: "#00b0ff" }} />
          </Box>
        </Box>
        <Box sx={{ display: "flex" }}>
          <Card
            sx={{ marginTop: "10px", marginBottom: "20px", padding: "20px" }}
          >
            <Typography>はじめまして。末吉 勇希と申します。</Typography>
            <Typography>
              このたびは、ポートフォリオ「SuekichiAI」をご覧いただき、誠にありがとうございます。
            </Typography>
            <Typography>
              本ポートフォリオでは、これまでの実務経験や独学で培ったスキルを最大限に活かし、私の強みを表現しております。
            </Typography>
            <Typography>
              貴重なお時間かと思いますが、ぜひご覧いただけますと幸いです。
            </Typography>
          </Card>
        </Box>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            marginTop: "10px",
          }}
        >
          <Box sx={{ width: "50%" }}>
            <Divider sx={{ borderColor: "#00b0ff" }} />
          </Box>
          <Typography
            variant="h6"
            sx={{ width: "900px", textAlign: "center", color: "#0091ea" }}
          >
            本ポートフォリオについて
          </Typography>
          <Box sx={{ width: "50%" }}>
            <Divider sx={{ borderColor: "#00b0ff" }} />
          </Box>
        </Box>
        <Box sx={{ display: "flex" }}>
          <Card
            sx={{ marginTop: "10px", marginBottom: "20px", padding: "20px" }}
          >
            <Typography>
              本ポートフォリオは「フロントエンド」「バッグエンド」「AI・機械学習」の3構成で作成をしております。
            </Typography>
            <Typography>
              使用している技術について以下の項目でご説明しておりますので、ぜひご覧ください。
            </Typography>
            <Typography sx={{ marginTop: "8px" }}>
              機能といたしましては以下5点となっております。
            </Typography>
            <Typography>
              ・ひらがなを手書きし、予測結果を表示します。
            </Typography>
            <Typography>
              ・単一の数字を手書きし、予測結果を表示します。
            </Typography>
            <Typography>
              ・複数の数字を手書きし、予測結果を表示します。
            </Typography>
            <Typography>
              ・認識ログをクラウドに保存し、確認することができます。
            </Typography>
            <Typography>
              ・全体(自分以外を含む)の認識ログについても、確認することができます。
            </Typography>
            <Typography sx={{ marginTop: "8px" }}>
              機械学習モデルとして「ランダムフォレスト分類モデル」を使用しておりますが、
            </Typography>
            <Typography>
              デザインの都合上、サイドメニュー上の表記を「RF」と略記しております。
            </Typography>
          </Card>
        </Box>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            marginTop: "10px",
          }}
        >
          <Box sx={{ width: "50%" }}>
            <Divider sx={{ borderColor: "#00b0ff" }} />
          </Box>
          <Typography
            variant="h6"
            sx={{ width: "400px", textAlign: "center", color: "#0091ea" }}
          >
            使用している技術
          </Typography>
          <Box sx={{ width: "50%" }}>
            <Divider sx={{ borderColor: "#00b0ff" }} />
          </Box>
        </Box>
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <Button
            variant="outlined"
            startIcon={<WebIcon />}
            onClick={() => setIsOpenFrontDialog(true)}
            sx={{ marginTop: "15px" }}
          >
            フロントエンド技術について見る
          </Button>
          <Button
            variant="outlined"
            startIcon={<ApiIcon />}
            onClick={() => setIsOpenBackDialog(true)}
            sx={{ marginTop: "10px" }}
          >
            バッグエンド・API技術について見る
          </Button>
          <Button
            variant="outlined"
            startIcon={<PsychologyAltIcon />}
            onClick={() => setIsOpenAIDialog(true)}
            sx={{ marginTop: "10px" }}
          >
            AI・機械学習技術について見る
          </Button>
        </Box>
      </Box>
    </div>
  );
}

export default MHome;
