import {
  Box,
  Button,
  Card,
  CircularProgress,
  Divider,
  FormHelperText,
  Link,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Paper,
  Typography,
} from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import ControlDialog from '../../../Util/commonComponent/controlDialog';
import EditIcon from '@mui/icons-material/Edit';
import RestartAltIcon from '@mui/icons-material/RestartAlt';

function ContextCanvasDialog(props) {
  const { isOpen, setIsOpen, isWrite, setIsWrite, imageData, setImageData } =
    props;
  const canvasRef = useRef(null);
  const [isDrawing, setIsDrawing] = useState(false);
  const [contextTmp, setContextTmp] = useState(null);
  const [isWriteTmp, setIsWriteTmp] = useState(false);

  useEffect(() => {
    if (isOpen) {
      // 初期設定
      setIsWriteTmp(isWrite);
      const tmpCanvasRef = canvasRef.current;

      // Canvasの初期設定
      tmpCanvasRef.width = 300; // キャンバスの幅
      tmpCanvasRef.height = 400; // キャンバスの高さ
      const ctx = tmpCanvasRef.getContext('2d');
      ctx.lineWidth = 25; // 線の太さ
      ctx.lineCap = 'round'; // 線の端を丸くする
      ctx.strokeStyle = 'black'; // 線の色
      if (imageData !== null) {
        const canvas = canvasRef.current;
        const ctx = canvas.getContext('2d');
        ctx.putImageData(imageData, 0, 0);
      } else {
        ctx.fillStyle = 'white'; // 背景色を白に設定
        ctx.fillRect(0, 0, tmpCanvasRef.width, tmpCanvasRef.height);
      }
      setContextTmp(ctx);
    }
  }, [isOpen]);

  // 座標補正関数
  const getMousePosition = (event) => {
    const canvas = canvasRef.current;
    const rect = canvas.getBoundingClientRect(); // キャンバスの位置とサイズを取得
    const original = event.nativeEvent;

    let x, y;
    if (original.changedTouches) {
      // タッチイベント
      x = original.changedTouches[0].pageX - rect.left; // キャンバスの位置を引いて相対座標を取得
      y = original.changedTouches[0].pageY - rect.top;
    } else {
      // マウスイベント
      x = event.pageX - rect.left; // キャンバスの位置を引いて相対座標を取得
      y = event.pageY - rect.top;
    }

    return [x, y];
  };

  const startDrawing = (event) => {
    const [x, y] = getMousePosition(event);
    contextTmp.beginPath(); // パスを開始
    contextTmp.moveTo(x, y); // パスの開始点
    setIsDrawing(true);
  };

  const draw = (event) => {
    if (!isDrawing) return;

    if (!isWriteTmp) {
      setIsWriteTmp(true);
    }

    const [x, y] = getMousePosition(event);
    contextTmp.lineTo(x, y); // パスを引く
    contextTmp.stroke(); // パスを描画
  };

  const stopDrawing = () => {
    contextTmp.closePath(); // パスを閉じる
    setIsDrawing(false);
  };

  const clearCanvas = () => {
    const tmpCanvas = canvasRef.current;
    const ctx = tmpCanvas.getContext('2d');

    // キャンバスを白で塗りつぶす
    ctx.fillStyle = 'white';
    ctx.fillRect(0, 0, tmpCanvas.width, tmpCanvas.height);
    setIsWriteTmp(false);
  };

  const save = () => {
    setIsOpen(false);
    setIsWrite(isWriteTmp);

    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    // ここでcontextがImageDataでない場合でも、canvasからImageDataを取得
    const i = ctx.getImageData(0, 0, canvas.width, canvas.height);
    setImageData(i);
  };

  return (
    <ControlDialog
      fullscreen={true}
      isOpen={isOpen}
      doYes={save}
      doNo={() => setIsOpen(false)}
      title={'キャンパス'}
      yesText={'描画を終了する'}
      noText={'キャンセル'}
      content={
        <Box>
          <Typography>青線を下にして描いてください。</Typography>
          <Card
            variant="outlined"
            sx={{
              marginTop: '5px',
              width: '300px',
              height: '400px',
              paddingTop: '10px',
              paddingLeft: '5px',
              paddingRight: '10px',
              paddingBottom: '10px',
              borderLeft: '5px solid #1976d2',
            }}
          >
            <canvas
              ref={canvasRef}
              style={{
                cursor: 'crosshair',
              }}
              onTouchStart={startDrawing}
              onTouchMove={draw}
              onTouchEnd={stopDrawing}
              onTouchCancel={stopDrawing}
              onMouseDown={startDrawing}
              onMouseMove={draw}
              onMouseUp={stopDrawing}
              onMouseLeave={stopDrawing}
            />
          </Card>
          <Button
            variant="outlined"
            sx={{ width: '320px', marginTop: '10px' }}
            startIcon={<RestartAltIcon />}
            onClick={clearCanvas}
          >
            キャンパスを白紙にする
          </Button>
        </Box>
      }
    />
  );
}

export default ContextCanvasDialog;
