import { PREDICT_TYPE } from '../../../../Util/define';
import CharAI from '../../../Control/showAI/charAI';
import './hiraganaCNN.css';

function HiraganaCNN(props) {
  return (
    <CharAI
      viewBreadcrumb={'ひらがな認識 - CNNモデル'}
      modelName={'CNN'}
      overview={`CNN分類モデルを使用したひらがな認識AIモデルです。
        テストデータに対し、97.98%と良好な精度を示しました。
        しかしながら、「あ」や「お」等、類似文字が存在するものに関しては認識精度がかなり落ちる傾向にあります。`}
      kindName={'ひらがな'}
      predictType={PREDICT_TYPE.HIRAGANA_CNN}
      keyname={'hiragana'}
      href={'https://aismiley.co.jp/ai_news/cnn/'}
    />
  );
}

export default HiraganaCNN;
