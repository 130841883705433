import { PREDICT_TYPE } from '../../../../Util/define';
import MCharAI from '../../../Control/showAI/charAI';
import CharAI from '../../../Control/showAI/charAI';
import './hiraganaTree.css';

function MHiraganaTree(props) {
  return (
    <MCharAI
      viewBreadcrumb={'ひらがな認識 - ランダムフォレストモデル'}
      modelName={'ランダムフォレスト'}
      overview={`ランダムフォレスト分類モデルを使用したひらがな認識AIモデルです。
        このモデルを作成する前に決定木分類モデルを作成しましたが、予測精度はかなりひどかったため、使用するモデルを変更しました。
        ランダムフォレスト分類モデルでは、テストデータに対し、97.2%と精度は高かったです。
        実践の予測結果はおおむねあっているように見受けられましたが、予測結果の差があまりないため、改良が必要です。`}
      kindName={'ひらがな'}
      predictType={PREDICT_TYPE.HIRAGANA_TREE}
      keyname={'hiragana'}
      href="https://aismiley.co.jp/ai_news/random-forests/"
    />
  );
}

export default MHiraganaTree;
