import {
  Box,
  Button,
  Card,
  Checkbox,
  CircularProgress,
  Divider,
  FormControlLabel,
  FormHelperText,
  Link,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Paper,
  Typography,
} from '@mui/material';
import './contextAI.css';
import React, { useEffect, useRef, useState } from 'react';
import ManageSearchOutlinedIcon from '@mui/icons-material/ManageSearchOutlined';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import LooksOneIcon from '@mui/icons-material/LooksOne';
import LooksTwoIcon from '@mui/icons-material/LooksTwo';
import Looks3Icon from '@mui/icons-material/Looks3';
import Looks4Icon from '@mui/icons-material/Looks4';
import Looks5Icon from '@mui/icons-material/Looks5';
import {
  ChatOpenAIWithMessage,
  FindContextPredictsTop3Myself,
  FindPredictsTop3Myself,
  PredictNumsByCNNModel,
  RegistContextPredict,
} from '../../../Util/web_api';
import { saveAs } from 'file-saver';
import { useRecoilValue } from 'recoil';
import { userInfo } from '../../../Util/recoil/atom';
import { FormatDateFromStrConsider, TimeToUTC } from '../../../Util/function';
import { PREDICT_ADDRESS, PREDICT_TYPE } from '../../../Util/define';
import ControlDialog from '../../../Util/commonComponent/controlDialog';
import EditIcon from '@mui/icons-material/Edit';
import ContextCanvasDialog from '../canvasDialog/contextCanvasDialog';
import imageImg from '../../../image/image.png';
import { CheckBox } from '@mui/icons-material';
import LaunchIcon from '@mui/icons-material/Launch';

function MContextAI(props) {
  const {
    viewBreadcrumb,
    modelName,
    overview,
    kindName,
    predictType,
    keyname,
    href,
  } = props;
  const canvasRef = useRef(null);
  const [context, setContext] = useState(null);
  const [imageData, setImageData] = useState(null);
  const [predicts, setPredicts] = useState([]);
  const [logs, setLogs] = useState([]);
  const [images, setImages] = useState([]);
  const [isOpenDialog, setIsOpenDialog] = useState(false);
  const [isWrite, setIsWrite] = useState(false);
  const [isLoad, setIsLoad] = useState(false);
  const [gptMessage, setGptMessage] =
    useState('OpenAIによる要約を表示します。');
  const [isOpenCanvasDialog, setIsOpenCanvasDialog] = useState(false);
  const [predict, setPredict] = useState([]);
  const [isOpenImageDialog, setIsOpenImageDialog] = useState(false);
  const [dontShow, setDontShow] = useState(false);

  // recoil
  const _userInfo = useRecoilValue(userInfo);

  useEffect(() => {
    findPredict();

    const isChecked = localStorage.getItem(
      'SUEKICHIAI_MobileContextImageChecked'
    );
    if (isChecked === null) {
      setIsOpenImageDialog(true);
    }

    // Canvasの初期設定
    const canvas = canvasRef.current;
    canvas.width = 300; // キャンバスの幅
    canvas.height = 225; // キャンバスの高さ
    const ctx = canvas.getContext('2d');
    ctx.lineWidth = 25; // 線の太さ
    ctx.lineCap = 'round'; // 線の端を丸くする
    ctx.strokeStyle = 'black'; // 線の色
    // 背景色を設定
    ctx.fillStyle = 'white'; // 背景色を白に設定
    ctx.fillRect(0, 0, canvas.width, canvas.height); // 背景を塗りつぶす
    setContext(ctx);
  }, []);

  useEffect(() => {
    if (imageData !== null) {
      const canvas = canvasRef.current;
      const ctx = canvas.getContext('2d');

      const { width, height } = imageData;

      // キャンバスのサイズを回転後に合わせる
      canvas.width = height * 0.75; // 回転後の幅に縮小率を適用
      canvas.height = width * 0.75; // 回転後の高さに縮小率を適用

      // 新しいimageDataを作成
      const rotatedImageData = ctx.createImageData(height, width);

      // 回転処理
      for (let y = 0; y < height; y++) {
        for (let x = 0; x < width; x++) {
          // 元の画像データのピクセルインデックス
          const originalIndex = (y * width + x) * 4;

          // 回転後のインデックスを計算 (90度回転)
          const newX = y;
          const newY = width - 1 - x;
          const newIndex = (newY * height + newX) * 4;

          // 元のピクセルデータを新しいimageDataにコピー
          rotatedImageData.data[newIndex] = imageData.data[originalIndex]; // R
          rotatedImageData.data[newIndex + 1] =
            imageData.data[originalIndex + 1]; // G
          rotatedImageData.data[newIndex + 2] =
            imageData.data[originalIndex + 2]; // B
          rotatedImageData.data[newIndex + 3] =
            imageData.data[originalIndex + 3]; // A
        }
      }

      // キャンバスの縮小を考慮して直接描画
      const tempCanvas = document.createElement('canvas');
      const tempCtx = tempCanvas.getContext('2d');

      // 一時的なキャンバスを回転後の画像サイズに設定
      tempCanvas.width = height;
      tempCanvas.height = width;

      // 回転後のimageDataを一時的なキャンバスに描画
      tempCtx.putImageData(rotatedImageData, 0, 0);

      // 一時的なキャンバスから本キャンバスに縮小して描画
      ctx.drawImage(tempCanvas, 0, 0, canvas.width, canvas.height);
    }
  }, [imageData]);

  useEffect(() => {
    if (predict.length > 0) {
      setIsLoad(true);
      ChatOpenAIWithMessage(
        `${JSON.stringify(
          predict
        )}これは手書き複数数字の認識結果です。それぞれの連想配列内のdigitsは、複数文字の認識結果を返しており、probabilityはそれらのprobabilityを足し合わせたものです。
結果的には何の文字であると推論されたのか、それ以外の推論と比較してどうなのかを120文字以内でまとめてください。
なお、probabilityの単位は「ポイント」です`,
        (res) => {
          setGptMessage(res.data.choices[0].message.content);
          setIsLoad(false);
        },
        (e) => {
          console.warn(e);
          setIsLoad(false);
        }
      );
    }
  }, [predict]);

  const registPredicts = (predicts, images, image) => {
    const childrenPoster = predicts.map((p, i) => {
      return {
        Index: i,
        Image: images[i],
        Predict1: p[0][keyname],
        Predict1Probability: p[0].probability,
        Predict2: p[1][keyname],
        Predict2Probability: p[1].probability,
        Predict3: p[2][keyname],
        Predict3Probability: p[2].probability,
        Predict4: p[3][keyname],
        Predict4Probability: p[3].probability,
        Predict5: p[4][keyname],
        Predict5Probability: p[4].probability,
      };
    });
    const poster = {
      UserRelationId: _userInfo.userId,
      PredictType: predictType,
      PredictDate: TimeToUTC(new Date()),
      Image: image,
      Children: childrenPoster,
    };

    RegistContextPredict(
      poster,
      (res) => {
        setLogs((prevData) => {
          // 先頭に新しいデータを追加し、末尾を削除
          if (logs.length === 3) {
            const updatedData = [
              res.data,
              ...prevData.slice(0, prevData.length - 1),
            ];
            return updatedData;
          } else {
            const updatedData = [res.data, ...prevData];
            return updatedData;
          }
        });
      },
      (e) => {
        console.warn(e);
      }
    );
  };

  const findPredict = () => {
    FindContextPredictsTop3Myself(
      _userInfo.userId,
      predictType,
      (res) => {
        setLogs(res.data);
      },
      (e) => {
        console.warn(e);
      }
    );
  };

  // 予測した結果を「文字列(123等)」と「確率(平均値)」の配列で返します
  const calcContextProbability = (predicts) => {
    // 全ての桁の組み合わせを作成
    const combinations = predicts.reduce(
      (acc, curr) =>
        acc.flatMap((combination) =>
          curr.map((item) => ({
            digits: [...combination.digits, item.digit],
            probability: combination.probability + item.probability,
          }))
        ),
      [{ digits: [], probability: 0 }]
    );
    const result = combinations
      .sort((a, b) => b.probability - a.probability)
      .slice(0, 5);

    // 確率の降順でソートし、上位5件を取得
    return result;
  };

  // 予測した結果を「文字列(123等)」と「確率(平均値)」の配列で返します
  const calcContextProbabilityFromLog = (predicts) => {
    const allPredictions = predicts.map((p) => [
      { digit: p.predict1, probability: p.predict1Probability },
      { digit: p.predict2, probability: p.predict2Probability },
      { digit: p.predict3, probability: p.predict3Probability },
      { digit: p.predict4, probability: p.predict4Probability },
      { digit: p.predict5, probability: p.predict5Probability },
    ]);
    return calcContextProbability(allPredictions);
  };

  const exportToBase64 = () => {
    if (!isWrite) {
      setIsOpenDialog(true);
      return;
    }
    const canvas = canvasRef.current;
    const dataURL = canvas.toDataURL('image/png'); // Base64形式の画像データを取得
    switch (predictType) {
      case PREDICT_TYPE.SENTENCE_CNN:
        PredictNumsByCNNModel(
          dataURL,
          (res) => {
            setPredicts(res.data.predicts);
            setImages(res.data.images);
            setPredict(calcContextProbability(res.data.predicts));
            registPredicts(res.data.predicts, res.data.images, dataURL);
          },
          (e) => {
            console.warn(e);
          }
        );
        break;
      default:
        break;
    }
  };

  return (
    <div className="mobileCharAI" style={{ paddingBottom: '100px' }}>
      <ControlDialog
        isOpen={isOpenImageDialog}
        doYes={() => {
          setIsOpenImageDialog(false);
          if (dontShow) {
            localStorage.setItem(
              'SUEKICHIAI_MobileContextImageChecked',
              'checked'
            );
          }
        }}
        yesText={'OK'}
        title={'モバイルモードでの注意点'}
        content={
          <Box>
            <Typography>
              モバイルモードでの複数文字認識は、機械学習データセットの画像サイズとの関係により、推論精度が著しく低くなります。
            </Typography>
            <img src={imageImg} width={'100%'} />
            <Typography>
              上記イメージのように、枠いっぱいに文字を書くと、推論精度が多少向上します。
            </Typography>
            <FormControlLabel
              label="以降表示しない"
              control={
                <Checkbox
                  checked={dontShow}
                  onChange={() => setDontShow(!dontShow)}
                />
              }
            />
          </Box>
        }
      />
      <ContextCanvasDialog
        isOpen={isOpenCanvasDialog}
        setIsOpen={setIsOpenCanvasDialog}
        isWrite={isWrite}
        setIsWrite={setIsWrite}
        imageData={imageData}
        setImageData={setImageData}
      />
      <ControlDialog
        isOpen={isOpenDialog}
        doYes={() => setIsOpenDialog(false)}
        yesText={'OK'}
        title={'無描画のためエラー'}
        content={
          <Typography>キャンパスに{kindName}を書いてください</Typography>
        }
      />
      <Typography>{viewBreadcrumb}</Typography>
      <Card variant="outlined" sx={{ padding: '10px' }}>
        <Typography>{'モデル概要'}</Typography>
        <Typography sx={{ marginLeft: '3px', marginTop: '3px' }}>
          {overview}
        </Typography>
        <Link
          href={href}
          target="_blank"
          rel="noopener noreferrer"
          sx={{
            display: 'flex',
            alignItems: 'center',
            width: modelName === 'ランダムフォレスト' ? '270px' : '160px',
          }}
        >
          <LaunchIcon />
          {`${modelName}モデルとは？`}
        </Link>
      </Card>
      <Box sx={{ marginTop: '20px', marginBottom: '10px' }}>
        <Typography>{`キャンパスに${kindName}を書き、認識ボタン押してください。`}</Typography>
      </Box>
      <Paper
        elevation={3}
        sx={{ padding: '10px', display: 'flex', flexDirection: 'column' }}
      >
        <Box
          sx={{
            width: '100%',
            paddingBottom: '20px',
            borderBottom: '1px solid #bdbdbd',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Card
            variant="outlined"
            sx={{
              padding: '10px',
              width: '300px',
              height: '225px',
              marginTop: '10px',
            }}
          >
            <canvas
              ref={canvasRef}
              style={{
                cursor: 'crosshair',
              }}
            />
          </Card>

          <Button
            variant="outlined"
            sx={{ width: '300px', marginTop: '10px' }}
            startIcon={<EditIcon />}
            onClick={() => setIsOpenCanvasDialog(true)}
          >
            キャンパスに{kindName}を書く
          </Button>
          <Button
            variant="contained"
            sx={{ marginTop: '5px', width: '300px' }}
            startIcon={<ManageSearchOutlinedIcon />}
            onClick={exportToBase64}
          >
            {kindName}を認識
          </Button>
        </Box>
        <Box
          sx={{
            paddingBottom: '18px',
            borderBottom: '1px solid #bdbdbd',
            marginBottom: '10px',
            paddingTop: '5px',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Typography sx={{ marginBottom: '5px' }}>認識結果</Typography>
          {predicts.length != 0 ? (
            <React.Fragment>
              <Box>
                <Box>
                  <Divider />
                  <List sx={{ padding: 0 }}>
                    {calcContextProbability(predicts).map((p, i) => (
                      <React.Fragment>
                        <ListItemButton>
                          <ListItemIcon>
                            {i === 0 ? (
                              <LooksOneIcon sx={{ color: '#fbc02d' }} />
                            ) : i === 1 ? (
                              <LooksTwoIcon sx={{ color: '#3f51b5' }} />
                            ) : i === 2 ? (
                              <Looks3Icon sx={{ color: '#ff6d00' }} />
                            ) : i === 3 ? (
                              <Looks4Icon />
                            ) : (
                              <Looks5Icon />
                            )}
                          </ListItemIcon>
                          <ListItemText
                            primary={`${p.digits.join('')}：${(
                              p.probability / predicts.length
                            ).toFixed(3)}%`}
                          />
                        </ListItemButton>
                        <Divider />
                      </React.Fragment>
                    ))}
                  </List>
                  <FormHelperText>上位5件を表示しています</FormHelperText>
                </Box>
                <Card
                  variant="outlined"
                  sx={{
                    padding: '10px',
                  }}
                >
                  <List sx={{ padding: 0 }}>
                    {predicts.map((p, i) => (
                      <React.Fragment>
                        {i !== 0 && <Divider />}
                        <ListItemButton>
                          <img src={images[i]} width={70} height={70} />
                          <Box sx={{ marginLeft: '10px' }}>
                            <Typography>{`${i + 1}文字目`}</Typography>
                            <Box>
                              <Typography
                                sx={{ display: 'flex', alignItems: 'center' }}
                              >
                                <LooksOneIcon
                                  sx={{ marginRight: '5px', color: '#fbc02d' }}
                                />
                                {`${p[0].digit}：${p[0].probability.toFixed(
                                  2
                                )}%`}
                              </Typography>
                              <Typography
                                sx={{ display: 'flex', alignItems: 'center' }}
                              >
                                <LooksTwoIcon
                                  sx={{ marginRight: '5px', color: '#3f51b5' }}
                                />
                                {`${p[1].digit}：${p[1].probability.toFixed(
                                  2
                                )}%`}
                              </Typography>
                              <Typography
                                sx={{ display: 'flex', alignItems: 'center' }}
                              >
                                <Looks3Icon
                                  sx={{ marginRight: '5px', color: '#ff6d00' }}
                                />
                                {`${p[2].digit}：${p[2].probability.toFixed(
                                  2
                                )}%`}
                              </Typography>
                            </Box>
                          </Box>
                        </ListItemButton>
                      </React.Fragment>
                    ))}
                  </List>
                </Card>
              </Box>

              <Card
                variant="outlined"
                sx={{
                  padding: '10px',
                  marginTop: '10px',
                }}
              >
                <Typography
                  variant="body1"
                  sx={{ color: '#03a9f4', marginBottom: '2px' }}
                >
                  AIによる認識結果評価
                </Typography>
                {isLoad ? (
                  <CircularProgress />
                ) : (
                  <Typography variant="body2">{gptMessage}</Typography>
                )}
              </Card>
            </React.Fragment>
          ) : (
            <Box>結果が表示されます</Box>
          )}
        </Box>
      </Paper>
      <Paper elevation={3} sx={{ marginTop: '10px' }}>
        <Box
          sx={{
            paddingRight: '18px',
            marginLeft: '13px',
            paddingTop: '10px',
            paddingBottom: '10px',
          }}
        >
          <Typography sx={{ marginBottom: '5px' }}>認識ログ</Typography>
          {logs.length != 0 ? (
            <List sx={{ padding: 0 }}>
              {logs.map((l, i) => {
                const p = calcContextProbabilityFromLog(l.children);
                if (p[0].digits.length === 0) {
                  return null;
                }
                return (
                  <React.Fragment>
                    {i === 0 && <Divider />}
                    <ListItemButton disableGutters>
                      <Box>
                        <Box sx={{ display: 'flex' }}>
                          <img src={l.image} width={150} height={112} />
                          <Box sx={{ marginLeft: '10px' }}>
                            <Typography>
                              {FormatDateFromStrConsider(l.predictDate, 9)}
                            </Typography>
                            <Box>
                              <Typography
                                sx={{ display: 'flex', alignItems: 'center' }}
                              >
                                <LooksOneIcon
                                  sx={{ marginRight: '5px', color: '#fbc02d' }}
                                />
                                {`${p[0].digits.join('')}：${(
                                  p[0].probability / p[0].digits.length
                                ).toFixed(3)}%`}
                              </Typography>
                              <Typography
                                sx={{ display: 'flex', alignItems: 'center' }}
                              >
                                <LooksTwoIcon
                                  sx={{ marginRight: '5px', color: '#3f51b5' }}
                                />
                                {`${p[1].digits.join('')}：${(
                                  p[1].probability / p[1].digits.length
                                ).toFixed(3)}%`}
                              </Typography>
                              <Typography
                                sx={{ display: 'flex', alignItems: 'center' }}
                              >
                                <Looks3Icon
                                  sx={{ marginRight: '5px', color: '#ff6d00' }}
                                />
                                {`${p[2].digits.join('')}：${(
                                  p[2].probability / p[2].digits.length
                                ).toFixed(3)}%`}
                              </Typography>
                            </Box>
                          </Box>
                        </Box>
                        {l.children.length > 0 && (
                          <Card
                            variant="outlined"
                            sx={{
                              marginTop: '10px',
                              padding: '10px',
                              display: 'flex',
                              flexDirection: 'column',
                              backgroundColor: 'transparent',
                            }}
                          >
                            {l.children.map((c, i) => {
                              return (
                                <Box
                                  sx={{ marginLeft: i === 0 ? '0px' : '10px' }}
                                >
                                  <Typography>{`${i + 1}文字目`}</Typography>
                                  <Box
                                    sx={{
                                      display: 'flex',
                                      alignItems: 'center',
                                    }}
                                  >
                                    <img src={c.image} height={100} />
                                    <Box>
                                      <Typography
                                        sx={{
                                          display: 'flex',
                                          alignItems: 'center',
                                        }}
                                      >
                                        <LooksOneIcon
                                          sx={{
                                            marginRight: '5px',
                                            color: '#fbc02d',
                                          }}
                                        />
                                        {`${c.predict1}：${c.predict1Probability}%`}
                                      </Typography>
                                      <Typography
                                        sx={{
                                          display: 'flex',
                                          alignItems: 'center',
                                        }}
                                      >
                                        <LooksTwoIcon
                                          sx={{
                                            marginRight: '5px',
                                            color: '#3f51b5',
                                          }}
                                        />
                                        {`${c.predict2}：${c.predict2Probability}%`}
                                      </Typography>
                                      <Typography
                                        sx={{
                                          display: 'flex',
                                          alignItems: 'center',
                                        }}
                                      >
                                        <Looks3Icon
                                          sx={{
                                            marginRight: '5px',
                                            color: '#ff6d00',
                                          }}
                                        />
                                        {`${c.predict3}：${c.predict3Probability}%`}
                                      </Typography>
                                    </Box>
                                  </Box>
                                </Box>
                              );
                            })}
                          </Card>
                        )}
                      </Box>
                    </ListItemButton>
                    <Divider />
                  </React.Fragment>
                );
              })}
            </List>
          ) : (
            <React.Fragment>
              <Divider />
              <Typography sx={{ padding: '10px' }}>
                ログが見つかりませんでした
              </Typography>
              <Divider />
            </React.Fragment>
          )}
          <FormHelperText>
            自分自身のログを直近3件表示しています。
          </FormHelperText>
        </Box>
      </Paper>
    </div>
  );
}

export default MContextAI;
